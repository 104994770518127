.blue-button {
    background-color: #15294E !important;
    color: white !important;
}

.blue-button:hover {
    background-color: white !important;
    color: #15294E !important;
}

.white-button {
    background-color: white;
    color: #15294E;
    /* padding: 5px 50px; */
}

.white-button:hover {
    background-color: #15294E !important;
    color: white !important;
}

.hover-blue:hover {
    background-color: #15294E !important;
    color: white !important;
}

.icon-hover:hover circle {
    fill: #15294E;
}

.icon-hover:hover path {
    fill: white;
}

.icon-hover-red:hover path {
    fill: white;
}

.icon-hover-red:hover circle {
    fill: #E45353;
}

.hover-red{
    background-color: white;
    color: #E45353 ;
}
.hover-red:hover {
    background-color: #E45353 !important;
    color: white !important;
}


/* weekdays */
.weekday-circle {
    width: 30px;
    height: 30px;
    font-size: 10px;
}